import { distribuitorBalance, eatContract, tantalusAccount } from "../../../common/bonnection/base";
import { currentTick } from "./current-tick";
import { percentageOf } from "./math";

export const getEatTotalDistributedAmount = async () => {
  const response = await eatContract().read.totalDistributedAmount();
  return BigInt(response as bigint);
}

export const getEatAverageDistributedAmount = async () => {
  const response = await eatContract().read.averageDistributedAmount();
  return BigInt(response as bigint);
}

export const getEatNextDestination = async () => {
  const response = await eatContract().read.nextDestination();
  return response as `0x${string}`;
}

export const getEatDistributedAmount = async (destination: `0x${string}`) => {
  const response = await eatContract().read.distributedAmount([destination]);
  return BigInt(response as bigint);
}

export const targetDistributionAmountNext = async () => {
  let targetAmount = await getEatAverageDistributedAmount();
  targetAmount = targetAmount + percentageOf(targetAmount, 1_0000n);
  const next = await getEatNextDestination();
  const nextAmountSoFar = await getEatDistributedAmount(next);
  let diffToAverage = targetAmount - nextAmountSoFar;
  if (diffToAverage < 0n) {
    diffToAverage = 0n;
  } else if (diffToAverage >= 0n) {
    diffToAverage += targetAmount;
  }
  // return diffToAverage > 0 ? diffToAverage : (diffToAverage == 0n ? targetAmount : 0n);
  return targetAmount;
}

export const isDistributionAvailable = async (min0: bigint, min1: bigint) => {
  const dBalance = await distribuitorBalance();
  const targetAmount = await targetDistributionAmountNext();

  return dBalance.weth > min0 || dBalance.usdc >= targetAmount * 2n;
}


export type EatStats = {
  total: bigint;
  average: bigint;
  next: `0x${string}`;
  nextAmount: bigint;
  nextTargetAmount: bigint;
  tantalusAmount: bigint;
  distribuitorBalance: {
    usdc: bigint;
    weth: bigint;
  }
}


export const eatStats = async (): Promise<EatStats> => {
  const total = await getEatTotalDistributedAmount();
  const average = await getEatAverageDistributedAmount();
  const next = await getEatNextDestination();
  const nextAmount = await getEatDistributedAmount(next);
  const tantalusAmount = await getEatDistributedAmount(tantalusAccount().address);
  const db = await distribuitorBalance();
  const target = await targetDistributionAmountNext();
  return {
    total,
    average,
    next,
    nextAmount,
    tantalusAmount,
    distribuitorBalance: db,
    nextTargetAmount: target
  }
}
