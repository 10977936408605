import { WheelInOutValues } from "../types";
import { fetchWheelBalance, getTotalActive, getWheelBalance } from "./balance";
import { currentTick } from "./current-tick";
import { getCashState, getHealth, getRebalanceState, getTotalOperationCost } from "./state";
import { frameState } from "./tick-state";
import { calculateValueOf0In1AtTick, calculateValueOf1In0AtTick, percentageOf } from "./math";
import { toLowerTickBound } from "./to-lower-tick-bound";
import { priceToTick, tantalusBalance } from "../../../common/bonnection/base";
import { formatUnits, parseUnits } from "viem";
import { getActiveStats } from "./active-stats";

export type CalculatedProfit = {
  green: boolean | undefined;
  isBuying: boolean | undefined;
  isSelling: boolean | undefined;
  rebalance: {
    amount0In: bigint;
    amount0Out: bigint;
    amount1In: bigint;
    amount1Out: bigint;
    oversold0: bigint;
    overbought0: bigint;
    overspent1: bigint;
    overheld1: bigint;
    bepPrice: bigint;
    sellBepPrice: bigint;
    buyBepPrice: bigint;
  },
  vsStaticHolding: bigint;
  vsFluidHolding: bigint;
  static0In: bigint;
  static1In: bigint;
  fluidAmount0In: bigint;
  fluidAmount1In: bigint;
  profit0: bigint;
  profit1: bigint;
  fluidProfit0: bigint;
  fluidProfit1: bigint;
  totalFluidProfit0: bigint;
  totalFluidProfit1: bigint;
  profitIn0: bigint;
  profitIn1: bigint;
  totalValueIn0: bigint;
  totalValueIn1: bigint;
  free0: bigint;
  free1: bigint;
  collateral0: bigint;
  collateral1: bigint
  debt0: bigint;
  debt1: bigint
  totalOperationCost: bigint;
  health: bigint;
  all0: bigint;
  all1: bigint;
  in1: {
    rebalance: {
      oversold0: bigint;
      overbought0: bigint;
      overspent1: bigint;
      overheld1: bigint;
      bepPrice: bigint;
      amount0In: bigint;
      amount0Out: bigint;
      amount1In: bigint;
      amount1Out: bigint;
      sellBepPrice: bigint;
      buyBepPrice: bigint;
    },
    livePrice: bigint;
    all0: bigint;
    all1: bigint;
    totalOperationCost: bigint;
    collateral0: bigint;
    collateral1: bigint;
    debt0: bigint;
    debt1: bigint;
    free0: bigint;
    free1: bigint;
    profit0: bigint;
    profit1: bigint;
    active0: bigint;
    active1: bigint;
    fluidProfit0: bigint;
    fluidProfit1: bigint;
    totalFluidProfit0: bigint;
    totalFluidProfit1: bigint;
    static0In: bigint;
    static1In: bigint;
    fluidAmount0In: bigint;
    fluidAmount1In: bigint;
  },
  in0: {
    livePrice: bigint;
    all0: bigint;
    all1: bigint;
    collateral0: bigint;
    collateral1: bigint;
    debt0: bigint;
    debt1: bigint;
    free0: bigint;
    free1: bigint;
    profit0: bigint;
    profit1: bigint;
    active0: bigint;
    active1: bigint;
    fluidProfit0: bigint;
    fluidProfit1: bigint;
    totalFluidProfit0: bigint;
    totalFluidProfit1: bigint;
    static0In: bigint;
    static1In: bigint;

    totalOperationCost: bigint;
    fluidAmount0In: bigint;
    fluidAmount1In: bigint;
    rebalance: {
      oversold0: bigint;
      overbought0: bigint;
      overspent1: bigint;
      overheld1: bigint;
      bepPrice: bigint;
      amount0In: bigint;
      amount0Out: bigint;
      amount1In: bigint;
      amount1Out: bigint;
      sellBepPrice: bigint;
      buyBepPrice: bigint;
    },
  },
  active0: bigint;
  active1: bigint;
  liveTick: bigint;
  livePrice: bigint;

}


export async function calculateProfit() {

  const ctr = await currentTick();

  const ct = toLowerTickBound(ctr);
  const totalOperationCost = await getTotalOperationCost();
  const cash: WheelInOutValues = await getCashState();
  const rebalanceState: WheelInOutValues = await getRebalanceState();
  const currentPrice = calculateValueOf0In1AtTick(Number(ctr), parseUnits("1", 18));
  const rebalance: {
    oversold0: bigint;
    overbought0: bigint;
    overspent1: bigint;
    overheld1: bigint;
    bepPrice: bigint;
    amount0In: bigint;
    amount0Out: bigint;
    amount1In: bigint;
    amount1Out: bigint;
    sellBepPrice: bigint;
    buyBepPrice: bigint;
  } = {
    oversold0: rebalanceState.amount0In < rebalanceState.amount0Out ? rebalanceState.amount0Out - rebalanceState.amount0In : 0n,
    overbought0: rebalanceState.amount0In > rebalanceState.amount0Out ? rebalanceState.amount0In - rebalanceState.amount0Out : 0n,
    bepPrice: 0n,
    sellBepPrice: 0n,
    buyBepPrice: 0n,
    overspent1: rebalanceState.amount1In < rebalanceState.amount1Out ? rebalanceState.amount1Out - rebalanceState.amount1In : 0n,
    overheld1: rebalanceState.amount1In > rebalanceState.amount1Out ? rebalanceState.amount1In - rebalanceState.amount1Out : 0n,
    amount0In: rebalanceState.amount0In,
    amount0Out: rebalanceState.amount0Out,
    amount1In: rebalanceState.amount1In,
    amount1Out: rebalanceState.amount1Out,
  };
  const activeStats = await getActiveStats();


  const balance = await fetchWheelBalance();
  const tBalance = await tantalusBalance();
  const frame = await frameState(ct, 15n, 15n);
  let active0 = 0n;
  let active1 = 0n;
  for (const state of frame) {
    active0 += state.active0;
    active1 += state.active1;
  }

  // static at 2745 price
  const static0In = parseUnits("2.14", 18);
  const static1In = parseUnits("5892", 6); //parseUnits("3000", 6) + parseUnits("8200", 6) + parseUnits("708", 6); //+ parseUnits("1242", 6);
  // visual 0.01% static  profit at 25 august 2024
  const amount0In = static0In;
  const amount1In = static1In;

  const free0 = balance.a0 - balance.d0;
  const free1 = balance.a1 - balance.d1;
  let profit0 = (balance.a0 - balance.d0 + balance.b0 + active0 + tBalance.eth + tBalance.weth) - amount0In;
  let profit1 = (balance.a1 - balance.d1 + balance.b1 + active1 + tBalance.usdc) - amount1In;

  const fluidAmount0In = cash.amount0In - cash.amount0Out;
  const fluidAmount1In = cash.amount1In - cash.amount1Out;

  let fluidProfit0 = (balance.a0 - balance.d0 + balance.b0 + active0) - fluidAmount0In;
  let fluidProfit1 = (balance.a1 - balance.d1 + balance.b1 + active1) - fluidAmount1In;


  if (profit0 < 0 && profit1 > 0) {
    // convert required 1 to 0 so profit 0 is 0
    const required1 = calculateValueOf0In1AtTick(Number(ctr), -profit0);
    profit1 -= required1;
    profit0 = 0n;
  } else if (profit1 < 0 && profit0 > 0) {
    // convert required 0 to 1 so profit 1 is 0
    const required0 = calculateValueOf1In0AtTick(Number(ctr), -profit1);
    profit0 -= required0;
    profit1 = 0n;
  }

  if (fluidProfit0 < 0 && fluidProfit1 > 0) {
    // convert required 1 to 0 so profit 0 is 0
    const required1 = calculateValueOf0In1AtTick(Number(ctr), -fluidProfit0);
    fluidProfit1 -= required1;
    fluidProfit0 = 0n;
  } else if (fluidProfit1 < 0 && fluidProfit0 > 0) {
    // convert required 0 to 1 so profit 1 is 0
    const required0 = calculateValueOf1In0AtTick(Number(ctr), -fluidProfit1);
    fluidProfit0 -= required0;
    fluidProfit1 = 0n;
  }


  let value0 = profit0 + calculateValueOf1In0AtTick(Number(ctr), profit1);
  let value1 = profit1 + calculateValueOf0In1AtTick(Number(ctr), profit0);

  let totalFluidProfit0 = fluidProfit0 + calculateValueOf1In0AtTick(Number(ctr), fluidProfit1);
  let totalFluidProfit1 = fluidProfit1 + calculateValueOf0In1AtTick(Number(ctr), fluidProfit0);


  const collateral0 = balance.a0;
  const collateral1 = balance.a1;
  const debt0 = balance.d0;
  const debt1 = balance.d1;

  const all0 = balance.a0 - balance.d0 + tBalance.eth + tBalance.weth + active0;
  const all1 = balance.a1 - balance.d1 + tBalance.usdc + active1;

  const totalValueIn0 = all0 + calculateValueOf1In0AtTick(Number(ctr), all1);
  const totalValueIn1 = all1 + calculateValueOf0In1AtTick(Number(ctr), all0);


  const staticNow = static1In + calculateValueOf0In1AtTick(Number(ctr), static0In);
  const fluidNow = fluidAmount1In + calculateValueOf0In1AtTick(Number(ctr), fluidAmount0In);
  const valueNow = totalValueIn1;


  const livePrice = calculateValueOf0In1AtTick(Number(ctr), parseUnits("1", 18));
  let shouldBuy: boolean | undefined = undefined;
  let shouldSell: boolean | undefined = undefined;
  let green: boolean | undefined = undefined;
  let missingStatic0 = 0n;
  let missingStatic1 = 0n;
  let missingFluid0 = 0n;
  let missingFluid1 = 0n;
  const actualStatic0In = static0In//0n;//parseUnits("0.178104873349036536", 18) + parseUnits("2.880583699217529655", 18) + parseUnits("0.4", 18);
  const actualStatic1In = static1In;//parseUnits("3000", 6); //+ parseUnits("1242", 6);
  if (actualStatic0In > 0n) {
    missingStatic0 = actualStatic0In - all0;

  }
  if (actualStatic1In > 0n) {
    missingStatic1 = actualStatic1In - all1;

  }

  if (fluidAmount0In > 0n) {
    missingFluid0 = fluidAmount0In - all0;

  }

  if (fluidAmount1In > 0n) {
    missingFluid1 = fluidAmount1In - all1;
  }



  let missing0 = missingStatic0//(missingFluid0 + missingStatic0) / 2n;
  let missing1 = missingStatic1//(missingFluid1 + missingStatic1) / 2n;


  const rebAmount0In = rebalance.amount0In - missing0 / 2n //+ activeStats.amount0In;
  const rebAmount0Out = rebalance.amount0Out + missing0 / 2n //+ activeStats.amount0Out;

  const rebAmount1In = rebalance.amount1In - missing1 / 2n //+ activeStats.amount1In;
  const rebAmount1Out = rebalance.amount1Out + missing1 / 2n //+ activeStats.amount1Out;

  const marketBuyingAt = livePrice - percentageOf(livePrice, 500n);
  const marketSellingAt = livePrice + percentageOf(livePrice, 500n);
  const weCanBuyFromMarketAt = marketSellingAt;
  const weCanSellToMarketAt = marketBuyingAt;
  const weHaveBoughtMoreThanSold = rebAmount0In > rebAmount0Out;
  const weHaveSoldMoreThanBought = rebAmount0In < rebAmount0Out;
  const weHaveSpentMoreThanReceived = rebAmount1In < rebAmount1Out;
  const weHaveReceivedMoreThanSpent = rebAmount1In > rebAmount1Out;
  const amountWeHaveBoughtMoreThanSold = rebAmount0In > rebAmount0Out ? rebAmount0In - rebAmount0Out : 0n;
  const amountWeHaveSoldMoreThanBought = rebAmount0In < rebAmount0Out ? rebAmount0Out - rebAmount0In : 0n;
  const amountWeHaveSpentMoreThanReceived = rebAmount1In < rebAmount1Out ? rebAmount1Out - rebAmount1In : 0n;
  const amountWeHaveEarnedMoreThanSpent = rebAmount1In > rebAmount1Out ? rebAmount1In - rebAmount1Out : 0n;

  let weBoughtAtPrice = 0n;
  let weSoldAtPrice = 0n;


  /*
    calculate bepSellPrice and bepBuyPrice in terms of 1 token
  */

  if (weHaveBoughtMoreThanSold) {
    weBoughtAtPrice = (rebAmount1Out - rebAmount1In) * parseUnits("1", 18) / amountWeHaveBoughtMoreThanSold;
  } else if (weHaveSoldMoreThanBought) {
    weSoldAtPrice = (rebAmount1In - rebAmount1Out) * parseUnits("1", 18) / amountWeHaveSoldMoreThanBought;
  }

  rebalance.sellBepPrice = weBoughtAtPrice;
  rebalance.buyBepPrice = weSoldAtPrice;

  if (rebalance.sellBepPrice > 0n
    && rebalance.sellBepPrice < weCanSellToMarketAt
    && weHaveBoughtMoreThanSold) {
    shouldSell = true;
    rebalance.sellBepPrice = weBoughtAtPrice;
  } else if (rebalance.buyBepPrice > 0n
    && rebalance.buyBepPrice > weCanBuyFromMarketAt
    && weHaveSoldMoreThanBought) {
    shouldBuy = true;
    rebalance.buyBepPrice = weSoldAtPrice;
  }

  /*
  if we have bought more than we have sold and we can sell to market at a higher price than we bought, we should sell
  */


  if ((rebAmount0In - rebAmount0Out) != 0n) {
    rebalance.bepPrice = (rebAmount1Out - rebAmount1In) * parseUnits("1", 18) / (rebAmount0In - rebAmount0Out);
  }
  /*
   */


  const result = {
    green: shouldBuy === true,
    isBuying: shouldBuy,
    isSelling: shouldSell,
    rebalance,
    vsStaticHolding: value0 - staticNow,
    vsFluidHolding: value0 - fluidNow,
    static0In,
    static1In,
    profit0,
    profit1,
    profitIn0: value0,
    profitIn1: value1,
    totalOperationCost,
    health: await getHealth(),
    free0,
    free1,
    collateral0,
    collateral1,
    debt0,
    debt1,
    totalValueIn0,
    totalValueIn1,
    all0,
    all1,
    active0,
    active1,
    fluidProfit0,
    fluidProfit1,
    totalFluidProfit0,
    totalFluidProfit1,
    fluidAmount0In,
    fluidAmount1In,
    in0: {
      all0,
      all1: calculateValueOf1In0AtTick(Number(ctr), all1),
      totalOperationCost,
      collateral0,
      collateral1: calculateValueOf1In0AtTick(Number(ctr), collateral1),
      debt0,
      debt1: calculateValueOf1In0AtTick(Number(ctr), debt1),
      free0,
      free1: calculateValueOf1In0AtTick(Number(ctr), free1),
      profit0,
      profit1: calculateValueOf1In0AtTick(Number(ctr), profit1),
      active0,
      active1: calculateValueOf1In0AtTick(Number(ctr), active1),
      fluidProfit0,
      fluidProfit1: calculateValueOf1In0AtTick(Number(ctr), fluidProfit1),
      totalFluidProfit0,
      totalFluidProfit1: calculateValueOf1In0AtTick(Number(ctr), totalFluidProfit1),
      static0In,
      static1In: calculateValueOf1In0AtTick(Number(ctr), static1In),
      livePrice: calculateValueOf1In0AtTick(Number(ctr), parseUnits("1", 6)),
      fluidAmount0In,
      fluidAmount1In: calculateValueOf1In0AtTick(Number(ctr), fluidAmount1In),
      rebalance: {
        oversold0: rebalance.oversold0,
        overbought0: rebalance.overbought0,
        overheld1: calculateValueOf1In0AtTick(Number(ctr), rebalance.overheld1),
        overspent1: calculateValueOf1In0AtTick(Number(ctr), rebalance.overspent1),
        bepPrice: calculateValueOf1In0AtTick(Number(ctr), rebalance.bepPrice),
        amount0In: rebalance.amount0In,
        amount0Out: rebalance.amount0Out,
        amount1In: calculateValueOf1In0AtTick(Number(ctr), rebalance.amount1In),
        amount1Out: calculateValueOf1In0AtTick(Number(ctr), rebalance.amount1Out),
        sellBepPrice: calculateValueOf1In0AtTick(Number(ctr), rebalance.sellBepPrice),
        buyBepPrice: calculateValueOf1In0AtTick(Number(ctr), rebalance.buyBepPrice),
      }
    },
    in1: {
      all0: calculateValueOf0In1AtTick(Number(ctr), all0),
      all1,
      totalOperationCost: calculateValueOf0In1AtTick(Number(ctr), totalOperationCost),
      collateral0: calculateValueOf0In1AtTick(Number(ctr), collateral0),
      collateral1,
      debt0: calculateValueOf0In1AtTick(Number(ctr), debt0),
      debt1,
      free0: calculateValueOf0In1AtTick(Number(ctr), free0),
      free1,
      profit0: calculateValueOf0In1AtTick(Number(ctr), profit0),
      profit1,
      active0: calculateValueOf0In1AtTick(Number(ctr), active0),
      active1,
      fluidProfit0: calculateValueOf0In1AtTick(Number(ctr), fluidProfit0),
      fluidProfit1,
      totalFluidProfit0: calculateValueOf0In1AtTick(Number(ctr), totalFluidProfit0),
      totalFluidProfit1,
      static0In: calculateValueOf0In1AtTick(Number(ctr), static0In),
      static1In,
      livePrice: calculateValueOf0In1AtTick(Number(ctr), parseUnits("1", 18)),
      fluidAmount0In: calculateValueOf0In1AtTick(Number(ctr), fluidAmount0In),
      fluidAmount1In,
      rebalance: {
        oversold0: calculateValueOf0In1AtTick(Number(ctr), rebalance.oversold0),
        overbought0: calculateValueOf0In1AtTick(Number(ctr), rebalance.overbought0),
        overspent1: rebalance.overspent1,
        overheld1: rebalance.overheld1,
        bepPrice: rebalance.bepPrice,
        amount0In: calculateValueOf0In1AtTick(Number(ctr), rebalance.amount0In),
        amount0Out: calculateValueOf0In1AtTick(Number(ctr), rebalance.amount0Out),
        amount1In: rebalance.amount1In,
        amount1Out: rebalance.amount1Out,
        sellBepPrice: rebalance.sellBepPrice,
        buyBepPrice: rebalance.buyBepPrice,
      }
    },
    liveTick: ctr,
    livePrice: livePrice,
  } as CalculatedProfit;



  return result;
}

export function isWheelBuying(profit: CalculatedProfit) {
  return profit.isBuying === true;
}

export function isWheelSelling(profit: CalculatedProfit) {

  return profit.isSelling === true;
}

export function isWheelNotRebaTrading(profit: CalculatedProfit) {
  return !isWheelBuying(profit) && !isWheelSelling(profit);
}

export const calculatedBepPriceAndTick = async (ratio0: bigint = 55_0000n, ratio1: bigint = 55_0000n, profit: bigint = 10_0000n): Promise<{ bepTick: bigint, bepPrice: bigint, bep0Tick: bigint, bep0Price: bigint, bep1Tick: bigint, bep1Price: bigint }> => {
  const response: bigint[] = await priceToTick().read.calculateBEP() as bigint[];


  const balances = await fetchWheelBalance(true);
  const totalActive = await getTotalActive();
  const opCost = await getTotalOperationCost();
  let freeToken0 = balances.a0 + balances.b0 + totalActive.token0;
  let freeToken1 = balances.a1 + balances.b1 + totalActive.token1;
  freeToken0 = percentageOf(freeToken0, ratio0);
  freeToken1 = percentageOf(freeToken1, ratio1);
  let debt0 = balances.d0 + opCost;
  let debt1 = balances.d1;

  const cash = await getCashState();
  const deposit0 = cash.amount0In > cash.amount0Out ? cash.amount0In - cash.amount0Out : 0n;
  const deposit1 = cash.amount1In > cash.amount1Out ? cash.amount1In - cash.amount1Out : 0n;

  debt0 += deposit0 > freeToken1 ? deposit0 - freeToken1 : 0n;
  debt1 += deposit1 > freeToken0 ? deposit1 - freeToken0 : 0n;

  const ct = await currentTick();
  const currentPrice = calculateValueOf0In1AtTick(Number(ct), parseUnits("1", 18));

  debt0 = percentageOf(debt0, 100_0000n + profit);
  debt1 = percentageOf(debt1, 100_0000n + profit);

  let bep0Tick = ct;
  let bep1Tick = ct;
  let bep0Price = currentPrice;
  let bep1Price = currentPrice;

  if (debt0 === 0n) {
    bep0Tick = ct;
    bep0Price = currentPrice;
  } else if (freeToken1 === 0n) {
    bep0Tick = ct - 50n;
    bep0Price = percentageOf(currentPrice, 90_0000n);
  } else {
    bep0Price = (freeToken1 * parseUnits("1", 18)) / debt0;
    bep0Tick = 0n; //BigInt(await fullPriceToTick(bep0Price));
  }

  if (debt1 === 0n) {
    bep1Tick = ct;
    bep1Price = currentPrice;
  } else if (freeToken0 === 0n) {
    bep1Tick = ct + 50n;
    bep1Price = percentageOf(currentPrice, 110_0000n);
  } else {
    bep1Price = (debt1 * parseUnits("1", 18)) / freeToken0;
    bep1Tick = 0n;//BigInt(await fullPriceToTick(bep1Price));
  }


  return {
    bepTick: BigInt(response[0]),
    bepPrice: BigInt(response[1]),
    bep0Tick: bep0Tick,
    bep0Price: bep0Price,
    bep1Tick: bep1Tick,
    bep1Price: bep1Price,
  }
}